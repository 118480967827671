import React from "react";
import "./stateful-ui.scss";
import noDataGeneric from "../../assets/img/no-data-generic.svg";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  UI_STATE_EMPTY,
  UI_STATE_FAILURE,
  UI_STATE_LOADING,
  UI_STATE_SUCCESS,
} from "../../static/strings";
import ImageLinks from "../../services/ImageLinks";
import Text from "../common/Text";
import theme from "../../services/theme";

class StatefulUi extends React.Component {
  getTemplate() {
    const {
      currentState,
      loaderText,
      failureStateImg,
      emptyStateImg,
      stateText,
    } = this.props;

    switch (currentState) {
      case UI_STATE_LOADING:
        return (
          <div className="stateful-ui loading">
            <ProgressSpinner />
            {loaderText && (
              <span className="stateful-ui__state-label">{loaderText}</span>
            )}
          </div>
        );
      case UI_STATE_SUCCESS:
        return this.props.children;
      case UI_STATE_EMPTY:
        return (
          <div className="stateful-ui empty">
            <img
              src={ImageLinks.empty_state_web}
              alt="empty"
              className="image_empty"
            />
            <Text
              semi
              style={{
                fontSize: 30,
                marginTop: 20,
                color: theme.colors.primary,
              }}
            >
              {stateText || "No Result Found"}
            </Text>
            <Text
              medium
              style={{
                fontSize: 20,
                color: theme.colors.darkGrey7,
                marginBottom: 20,
                textAlign: "center",
              }}
            >
              At this time, there is no information available. Try again soon.
            </Text>
            <a
              href="https://pidge.in/"
              style={{
                color: "#fff",
                textDecoration: "unset",
                marginBottom: 40,
              }}
              className="p-button-raised p-button-rounded pidge-btn pidge-footer__download-btn"
            >
              Check out pidge.in
            </a>
          </div>
        );
      case UI_STATE_FAILURE:
        return (
          <div className="stateful-ui failure">
            <img
              src={ImageLinks.error_image_web}
              alt="failure"
              className="image"
            />
            <Text
              semi
              style={{
                fontSize: 30,
                marginTop: 20,
                color: theme.colors.primary,
              }}
            >
              Hold on!
            </Text>
            <Text
              medium
              style={{
                fontSize: 20,
                color: theme.colors.darkGrey7,
                marginBottom: 20,
                textAlign: "center",
              }}
            >
              We're having trouble loading this page. Try again soon.
            </Text>
            <a
              href="https://pidge.in/"
              style={{
                color: "#fff",
                textDecoration: "unset",
                marginBottom: 40,
              }}
              className="p-button-raised p-button-rounded pidge-btn pidge-footer__download-btn"
            >
              Check out pidge.in
            </a>
          </div>
        );
      default:
        return (
          <div className="stateful-ui empty">
            {emptyStateImg ? (
              <img src={emptyStateImg} alt="Empty" />
            ) : (
              <img src={noDataGeneric} alt="Empty" />
            )}
            <span className="stateful-ui__state-label">
              {stateText || "No data"}
            </span>
          </div>
        );
    }
  }

  render() {
    return this.getTemplate();
  }
}

export default StatefulUi;
