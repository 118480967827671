import {action, makeObservable, observable} from "mobx";

class GlobalStore {
    constructor() {
        this.phone = '9199940472';
        this.user = 'Miam Patisserie';
        this.awb = '24578';

        makeObservable(this, {
            phone: observable,
            user: observable,
            awb: observable,
            setLoginCredentials: action,
            setUser: action,
        });
    }

    setLoginCredentials(phone, awb) {
        this.phone = phone;
        this.awb = awb;
    }

    setUser(user) {
        this.user = user;
    }
}

export default new GlobalStore();
