import axios from "axios";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
let axiosInstance=null

export const axiosInstanceinit = (api_url) => {
  axiosInstance = axios.create({
    baseURL: api_url,
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      // Authorization: "eyJhbGciOiJIjnjsUzI1NiIsInR5cCI6IkpX76T7hf",
    },
  });
  
}


export function getDeliveryInfo(mobile, mcpID) {
  return axiosInstance.get(`/order-tracking?mobile=${mobile}&mcp_id=${mcpID}`, {
    cancelToken: source.token,
  });
}

export function getDeliveryInfoForTrackingCode(trackCode) {
  return axiosInstance.get(`/order-tracking?code=${trackCode}`, {
    cancelToken: source.token,
  });
}

export function getDeliveryInformationForTrackingCode(trackCode) {
  return axiosInstance.get(
    `/store/tracking/track-order?trackCode=${trackCode}`,
    {
      cancelToken: source.token,
    }
  );
}

export function getDeliveryInformationForCdrId(id) {
  return axiosInstance.get(`/store/tracking/track-order?id=${id}`, {
    cancelToken: source.token,
  });
}

export function getDeliveryInformation(mobile, awb) {
  return axiosInstance.get(
    `/store/tracking/track-order?mobile=${mobile}&awb=${awb}`,
    {
      cancelToken: source.token,
    }
  );
}

export function saveFeedbackInfomation(data) {
  return axiosInstance.post(`/store/tracking/submit-feedback`, data, {
    cancelToken: source.token,
  });
}

export function getRiderLocation(id) {
  return axiosInstance.get(`/store/tracking/rider-location?id=${id}`, {
    cancelToken: source.token,
  });
}

export function getTrackingConfigs(track_code) {
  return axiosInstance.get(
    `/store/tracking/track-order/config?trackCode=${track_code}`,
    {
      cancelToken: source.token,
    }
  );
}
