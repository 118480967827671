import "./App.scss";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import NavbarHorizontal from "./components/navbar-horizontal/navbar-horizontal";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { REDIRECTION_URL, ROUTE_NAME_LOGIN } from "./static/strings";
import LoginPage from "./pages/auth/login/login.page";
// import HomePage from "./pages/home/home.page";
// import Footer from "./components/footer/footer";
import "primereact/resources/themes/bootstrap4-light-purple/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import globalStore from "./stores/GlobalStore";
import GlobalContext from "./contexts/GlobalContext";
import NewHomePage from "./pages/NewHomePage";
import constant from "./services/constant";
import loadjs from "loadjs";
import ReactGA from "react-ga4";
import Footer from "./components/footer/footer";
import * as Sentry from "@sentry/react";

library.add(faChevronDown, faCaretDown);

const App = () => {
  const [user, setUser] = useState(null);
  const onUserSignout = useCallback(() => {
    setUser(null);
  }, []);

  useEffect(() => {
    ReactGA.initialize("G-E7ZJKGZ6T8", {
      debug: constant.ENVIRONMENT === constant.env.STAGING,
    });
    loadjs(
      `https://maps.googleapis.com/maps/api/js?sensor=false&key=${constant.GOOGLE_MAP_KEY}&v=3.exp&libraries=places`
    );
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<h1>Something went wrong.</h1>}>
      <GlobalContext.Provider value={globalStore}>
        <div className="App">
          <Router>
            {/* <NavbarHorizontal onUserSignout={onUserSignout} /> */}
            <div className="content">
              <Route
                path="/login"
                render={(props) => {
                  if (user) {
                    const redirectURL = localStorage.getItem(REDIRECTION_URL);

                    if (redirectURL) {
                      return <Redirect to={redirectURL} />;
                    }
                  } else {
                    return <LoginPage {...props} setUser={setUser} />;
                  }
                }}
              />
            </div>
            <Route
              path="/"
              render={(props) => {
                return <NewHomePage user={user} {...props} />;
              }}
            />
            <Footer />
          </Router>
        </div>
      </GlobalContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
