import _ from "lodash";
import ImageLinks from "./ImageLinks";

let utils = {
  get_partner_img: (partner) => {
    switch (partner.toLowerCase()) {
      case "pidge":
        return ImageLinks.pidge_logo;
      case "dunzo":
        return ImageLinks.dunzo_logo;
      case "captive":
        return ImageLinks.self_fulfilled_logo;
      case "wefast":
        return ImageLinks.wefast_logo;
      case "loadshare":
        return ImageLinks.loadshare_logo;
      case "shadowfax":
        return ImageLinks.shadowfax_logo;
      case "zomato":
        return ImageLinks.zomato_logo;
      case "porter":
        return ImageLinks.porter_logo;
      default:
        break;
    }
  },
};

export default utils;
