import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import constant from "./services/constant";
import { axiosInstanceinit } from "./services/api.service";
import * as Sentry from "@sentry/react";
import { matchPath } from "react-router-dom";
import history from "./browserHistory";

const initialize_app = async (response) => {
  const json = await response.json();
  constant.ENVIRONMENT = json.ENVIRONMENT;
  constant.API_URL = json.API_URL;
  constant.GOOGLE_MAP_KEY = json.GOOGLE_MAP_KEY;

  axiosInstanceinit(json.API_URL);

  const routes = [];

  Sentry.init({
    dsn: constant.SENTRY_DSN,
    enabled: json.ENVIRONMENT === constant.env.PRODUCTION,
    ignoreErrors: [
      "handle_click_outside",
      "Cannot read properties of null (reading 'removeLayer')",
      "Map: Expected mapDiv of type HTMLElement but was passed null.",
      "Cannot read properties of undefined (reading 'pathname')",
      "Uncompressed Asset",
      "Retries exceeded",
      "className.includes is not a function",
      "undefined is not an object (evaluating 'e.pathname')",
      "Cannot read properties of undefined (reading 'maps')",
    ],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history,
          routes,
          matchPath
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: json.ENVIRONMENT,
    release: "release-v2",

    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "console") {
        const is_match =
          breadcrumb.message.match(/Google Maps JavaScript API/i) ||
          breadcrumb.message.match(
            /Warning: Failed %s type: %s%s prop Material-UI/i
          ) ||
          breadcrumb.message.match(/Warning: validateDOMNesting/i) ||
          breadcrumb.message.match(
            /Deprecation warning: value provided is not in a recognized RFC2822 or ISO format/i
          ) ||
          breadcrumb.message.match(
            /Warning: componentWillUpdate has been renamed/i
          );
        return is_match ? null : breadcrumb;
      }
      return breadcrumb;
    },
  });

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );

  reportWebVitals();
};

fetch("/env_constant.json").then(initialize_app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
