import React from "react";
import _ from "lodash";
import { Accordion, AccordionTab } from "primereact/accordion";
import trackingDots from "../../assets/img/tracking-dots.svg";
import moment from "moment";
import { orderStatusInformation } from "../../pages/NewHomePage/utils";

const ViewDetails = ({ activeIndex, setActiveIndex, logs }) => {
  const reverse_view_details = [...logs].reverse();
  return (
    <div className="view-tracking-details">
      <Accordion
        multiple
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <AccordionTab>
          <div className="mt-2">
            {_.map(reverse_view_details, (logValue, index) => {
              return (
                <div className="d-flex" key={index}>
                  <div>
                    <div style={{ position: "relative" }}>
                      <img
                        src={trackingDots}
                        alt="tracking-dots"
                        style={{ marginTop: 8 }}
                      />
                      {index !== logs.length - 1 && (
                        <div
                          className="vertical-line"
                          style={{ position: "absolute", left: 6 }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="track-info-box" style={{ flexGrow: 9 }}>
                    <div className="space-between-container">
                      <div>
                        <div className="tracking-info-heading">
                          {orderStatusInformation(logValue.status)}
                        </div>
                        <div className="tracking-info-subheading">
                          {logValue.remark}
                        </div>
                      </div>
                      <div>
                        <div className="tracking-info-heading">
                          {moment(logValue.timestamp).format("h:mm a")}
                        </div>
                        <div className="tracking-info-subheading">
                          {moment(logValue.timestamp).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default ViewDetails;
