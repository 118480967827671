const env = {
  STAGING: "Staging",
  PRODUCTION: "Production",
};

let ENVIRONMENT = env.STAGING;

if (process.env.REACT_APP_ENV === "prod") {
  ENVIRONMENT = env.PRODUCTION;
}

const constant = {
  env,
  ENVIRONMENT,
  API_URL: "",
  // API_URL:
  //   ENVIRONMENT === env.STAGING
  //     ? "https://uat-store.pidge.in/v1.0"
  //     : "https://api.pidge.in/v1.0",
  GOOGLE_MAP_KEY: "",
  // GOOGLE_MAP_KEY:
  //   ENVIRONMENT === env.PRODUCTION
  //     ? "AIzaSyASjGKlFafGpHWa37_3MQcpsxaSFBFRSTU"
  //     : "AIzaSyDHgbNwCshqEti3E1bDvPsIYB-27bWxYvA",
  MAP_DESIGN_ID: "d4fa8af386e6edc8",
  SENTRY_DSN:
    "https://7c48d17a5e810ea6615ade09753a2c58@o274231.ingest.us.sentry.io/4506896399335424",
};

export default constant;
