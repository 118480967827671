import React, { useEffect, useState } from "react";
import "./login.page.scss";
import produce from "immer";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import {
  TOAST_MESSAGE_TYPE_ERROR,
  TOAST_MESSAGE_TYPE_SUCCESS,
} from "../../../static/strings";
import { Button } from "primereact/button";
import { has, set } from "lodash";
import { useLocationQuery } from "../../../hooks/useLocationQuery";

const LoginPage = ({ setUser }) => {
  const query = useLocationQuery();
  const phoneRegex = new RegExp("^[6-9]\\d{9}$");
  const awbRegex = new RegExp("^[0-9]\\d*$");
  const [isLoading, setIsLoading] = useState(false);

  const initialState = {
    phone: {
      value: query.get("phone") || "",
      error: false,
      errorMsg: "",
    },
    awb: {
      value: query.get("awb") || "",
      error: false,
      errorMsg: "",
    },
  };

  const enhancedReducer = (state, updateArg) => {
    // check if the type of update argument is a callback function
    if (updateArg.constructor === Function) {
      return { ...state, ...updateArg(state) };
    }

    // if the type of update argument is an object
    if (updateArg.constructor === Object) {
      // Update nested values here
      if (has(updateArg, "_path") && has(updateArg, "_value")) {
        const { _path, _value } = updateArg;

        return produce(state, (draft) => {
          set(draft, _path, _value);
        });
      }

      // Update root values here
      else {
        return { ...state, ...updateArg };
      }
    }
  };

  const [state, updateState] = React.useReducer(enhancedReducer, initialState);

  const toastRef = React.useRef();

  const updateForm = React.useCallback(({ target: { value, name, type } }) => {
    // Since update path is using dot notation
    const updatePath = name.split(".");

    // if we have to update the root level nodes in the form
    if (updatePath.length === 1) {
      const [key] = updatePath;

      updateState({
        [key]: value,
      });
    }

    // if we have to update nested nodes in the form object
    // use _path and _value to update them.
    if (updatePath.length === 2) {
      updateState({
        _path: updatePath,
        _value: value,
      });
    }
  }, []);

  useEffect(() => {
    const phone = query.get("phone");
    const awb = query.get("awb");
    if (phone && awb) {
      setUser({ phone, awb });
    }
  }, []);

  const onFormSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    if (!state.phone.value || !state.awb.value) {
      setIsLoading(false);
      toastRef.current.show({
        severity: TOAST_MESSAGE_TYPE_ERROR,
        summary: "Invalid Form",
        detail: "Either of the fields cannot be empty.",
      });
    } else {
      if (
        phoneRegex.test(state.phone.value) &&
        awbRegex.test(state.awb.value)
      ) {
        setIsLoading(false);
        toastRef.current.show({
          severity: TOAST_MESSAGE_TYPE_SUCCESS,
          summary: "Success",
          detail: "Signed in successfully.",
        });
        setUser({ phone: state.phone.value, awb: state.awb.value });
      } else {
        setIsLoading(false);
        toastRef.current.show({
          severity: TOAST_MESSAGE_TYPE_ERROR,
          summary: "Invalid Form",
          detail: "Invalid credentials.",
        });
      }
    }
  };

  return (
    <div className="login-page content-container">
      <div className="login-page__card auth-card">
        <h2 className="login-page__card-title color-primary">Welcome!</h2>
        <p className="login-page__card-subtitle">
          Please enter details to track your package
        </p>

        <form onSubmit={onFormSubmit}>
          <span className="p-input-icon-right">
            {state.phone.error && <i className="pi pi-info" />}
            <InputText
              placeholder="Phone Number"
              id="phone"
              value={state.phone.value}
              name="phone.value"
              onChange={updateForm}
            />
            {state.phone.error && (
              <small id="phone-help" className="p-error p-d-block">
                {state.phone.errorMSG}
              </small>
            )}
          </span>

          <span className="p-input-icon-right">
            {state.awb.error && <i className="pi pi-info" />}
            <InputText
              placeholder="AWB Number"
              id="awb"
              value={state.awb.value}
              name="awb.value"
              onChange={updateForm}
            />
            {state.awb.error && (
              <small id="awb-help" className="p-error p-d-block">
                {state.awb.errorMSG}
              </small>
            )}
          </span>

          <Button
            type="submit"
            loading={isLoading}
            label="Track"
            className="p-button-raised p-button-rounded submit-btn pidge-btn"
          />
        </form>
      </div>

      <Toast ref={toastRef} />
    </div>
  );
};

export default LoginPage;
