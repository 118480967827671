import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(() => ({
  light: {
    fontFamily: "HK Grotesk Light",
  },
  regular: {
    fontFamily: "HK Grotesk Regular",
  },
  medium: {
    fontFamily: "HK Grotesk Medium",
  },
  semi: {
    fontFamily: "HK Grotesk SemiBold",
  },
  bold: {
    fontFamily: "HK Grotesk Bold",
  },
}));

const Text = ({
  children,
  component,
  className,
  bold,
  medium,
  semi,
  light,
  ...rest
}) => {
  const Component = component || "p";

  const classes = useStyles();

  let fontFamilyClassName = classes.regular;

  if (light) {
    fontFamilyClassName = classes.light;
  }

  if (medium) {
    fontFamilyClassName = classes.medium;
  }

  if (semi) {
    fontFamilyClassName = classes.semi;
  }

  if (bold) {
    fontFamilyClassName = classes.bold;
  }

  return (
    <Component className={clsx(fontFamilyClassName, className)} {...rest}>
      {children}
    </Component>
  );
};

export default Text;
