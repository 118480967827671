const ImageLinks = {
  dunzo_logo: require("../assets/img/dunzo_logo.svg").default,
  wefast_logo: require("../assets/img/wefast_logo.svg").default,
  self_fulfilled_logo: require("../assets/img/self_fulfilled_logo.svg").default,
  pidge_logo: require("../assets/img/pidge-logo.svg").default,
  zomato_logo: require("../assets/img/zomato_logo.svg").default,
  porter_logo: require("../assets/img/porter_logo.svg").default,
  loadshare_logo: require("../assets/img/loadshare_logo.svg").default,
  shadowfax_logo: require("../assets/img/shadowfax_logo.svg").default,
  make_in_india: require("../assets/img/make_in_india.svg").default,
  digital_india: require("../assets/img/digital_india.svg").default,
  error_image_web: require("../assets/img/error_image_web.svg").default,
  error_image_mobile: require("../assets/img/error_image_mobile.svg").default,
  empty_state_web: require("../assets/img/empty_state_web.svg").default,
  empty_state_mobile: require("../assets/img/empty_state_mobile.svg").default,
  alert: require("../assets/img/alert.svg").default,
};

export default ImageLinks;
